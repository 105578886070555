<template>
	<div class="container seller-auth">
        <div class="box">
            <section class="form pattern-seller">
                <div class="wrapper">
                    <h1>เข้าสู่ระบบจัดการร้านค้า</h1>

                    <div class="input-field">
                        <label>หมายเลขโทรศัพท์</label>
                        <input type="text"
                            maxlength="100"
                            v-model="form.username"
                            placeholder="หมายเลขโทรศัพท์"
                            class="square lg" 
                            :class="{ 'invalid': error.username }" />
                    </div>
                    <div class="input-field mt-3">
                        <label>รหัสผ่าน</label>
                        <div class="input-password">
                            <input :type="isShowPassword ? 'text' : 'password'"
                                @keyup.enter="login()"
                                maxlength="50"
                                v-model="form.password"
                                placeholder="รหัสผ่าน"
                                class="square lg" 
                                :class="{ 'invalid': error.password }" />
                            <button class="show-password" @click="showPassword()"><b-icon :icon="isShowPassword ? 'eye-slash-fill' : 'eye-fill'"></b-icon></button>
                        </div>
                    </div>
                    <div class="mt-4 text-center">
                        <button class="btn btn-main btn-submit" @click="login()">เข้าสู่ระบบ</button>
                    </div>
                    <div class="menu">
                        <router-link :to="routerPath.SELLER_FORGET_PASSWORD">ลืมรหัสผ่านร้านค้า?</router-link>
                    </div>

                    <div class="term mt-4">
                        ข้าพเจ้าตกลงตาม <router-link :to="routerPath.STATIC_TERM_STORE" target="_blank" title="ข้อกำหนดการใช้งาน">ข้อกำหนดการเปิดร้านค้า</router-link> และ <router-link :to="routerPath.STATIC_PRIVACY" target="_blank" title="นโยบายความเป็นส่วนตัว">นโยบายความเป็นส่วนตัว</router-link>
                    </div>
                </div>
            </section>

            <section class="link pattern-seller">
                <div class="wrapper">
                    <h2>อยากขายของกับเรา?</h2>
                    <p>เปิดร้านกับ Yellowtire ลงสินค้าง่าย และ ค่าธรรมเนียมต่ำ ท่านสามารถกดลิงค์ด้านล่างเพื่อขายสินค้ากับเรา</p>

                    <seller-regis-benefit></seller-regis-benefit>

                    <div class="action">
                        <router-link class="btn btn-warning" :to="routerPath.SELLER_REGISTER">สมัครร้านค้าใหม่</router-link>
                    </div>
                </div>
            </section>
        </div>
	</div>
</template>

<script>
import RouterPath from '@/router/path';
import Helper from '@/services/helper';
import SellerService from '@/services/sellerService';
import SellerRegisBenefit from '@/components/seller/RegisBenefit';

export default {
    components: {
        SellerRegisBenefit
    },
	data() {
        return {
            routerPath: RouterPath,
			isShowPassword: false,
            isActive: false,
            form: this.initForm(),
            error: this.initError()
        }
    },
    methods: {
        initForm() {
            return {
                username: '',
                password: '',
                recaptcha: null
            };
        },
        initError() {
            return {
                username: false,
                password: false
            };
        },
        async login() {
            if (this.validate()) {
                let loader = this.$modalLoader.render();

                this.form.recaptcha = await this.getRecaptchaToken();
                const result = await SellerService.submitLogin(this.form);

                loader.hide();

                if (result.data?.success) {
                    SellerService.setSellerToken(result.data.token);
                    
                    localStorage.setItem('shopInfo', JSON.stringify(result.data.info));
                    localStorage.setItem('shopAcl', JSON.stringify(result.data.acl));

                    this.$router.push(result.data.menu);
                } else {
                    this.$swal(Helper.warningAlert('เข้าสู่ระบบไม่สำเร็จ', result.data?.message));
                }
            }
        },
        async getRecaptchaToken() {
            await this.$recaptchaLoaded()

            const recaptcha = await this.$recaptcha('sellerLogin');

            return recaptcha;
        },
        validate() {
            this.error = this.initError();
            let isValid = true;
            let isBlank = false;

            // Check Blank
            for (const property in this.error) {
                if (!this.form[property]?.trim()) {
                    this.error[property] = true;
                    isValid = false;
                    isBlank = true;
                }
            }

            // Popup
            if (isBlank) {
                this.$swal(Helper.warningAlert('เข้าสู่ระบบไม่สำเร็จ', 'โปรดกรอกข้อมูลให้ครบถ้วน'));
            }

            return isValid;
        },
        showPassword() {
            this.isShowPassword = !this.isShowPassword;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/user/style/Authentication.scss';
</style>